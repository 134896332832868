import '../sass/frontend.scss'

import $ from "jquery";

$(document).ready(function () {
	addFullLink();
    initiateSwiper();
});

function addFullLink() {
    var blocks = $('.clickable-blocks-home').find('.gb-grid-column');
    blocks.each(function () {
        var anchor = $(this).find('a');
        if (anchor.length > 0) {
            var link = anchor.attr('href');
            $(this).click(function () {
                window.location.href = link;
            })
        }
    })
}

function initiateSwiper() {

    var logos_slider = $('.testimonials');
    if (logos_slider.length) {
        var swiper = new Swiper('.testimonials', {
            slidesPerView: 1,
            loop: false,
            spaceBetween: 0,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }
}